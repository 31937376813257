/*
* Accessibility
* Avoid the color/font change blink (Here, not inside the JS)
* Need to read after the Cookies (inject-head is before)
* Converted from jQuery to javascript
* Here also to minify
*/
window.accorAccessibilityStatus = {
    // color: 'default',
    font: 'normal',
    letterSpacing: 'normal',
    animation: 'normal',
};


// var btn_acc_by = document.getElementById('acc_by');
// var btn_acc_bw = document.getElementById('acc_bw');
var btn_acc_spacing = document.getElementById('acc_larger_spacing');
var btn_acc_font = document.getElementById('acc_readable_font');
var btn_acc_anim = document.getElementById('acc_no_animation');
var btn_acc_reset = document.getElementById('acc_reset');
var html = document.getElementsByTagName("html")[0];

var accorAccessibilityCookieName = 'accor_accessibility';
(function () {
    var tmp = Cookies.get(accorAccessibilityCookieName);
    if (tmp) {
        try {
            var obj = JSON.parse(tmp);
            // if (typeof obj.color !== 'undefined') {
            //     accorAccessibilityStatus.color = obj.color;
            // }
            // if (typeof obj.fontSize !== 'undefined') {
            //     accorAccessibilityStatus.fontSize = obj.fontSize;
            // }
            if (typeof obj.letterSpacing !== 'undefined') {
                accorAccessibilityStatus.letterSpacing = obj.letterSpacing;
            }
            if (typeof obj.font !== 'undefined') {
                accorAccessibilityStatus.font = obj.font;
            }
            if (typeof obj.animation !== 'undefined') {
                accorAccessibilityStatus.animation = obj.animation;
            }
        } catch (e) {
        }
        applyAccessibilityStatus();
    }
})();

function saveAccessibilityStatus() {
    var str = JSON.stringify(accorAccessibilityStatus);
    Cookies.set(accorAccessibilityCookieName, str, 1000);
}

var countTime = 0;

function applyAccessibilityStatus(reset) {
    reset = reset || false;
    // if (accorAccessibilityStatus.color === 'bw') {
    //     html.classList.add("accessibility_BW");
    //     html.classList.remove("accessibility_BY");
    //     btn_acc_by.classList.remove('active');
    //     btn_acc_bw.classList.add('active');
    // }
    // if (accorAccessibilityStatus.color === 'by') {
    //     html.classList.add("accessibility_BY");
    //     html.classList.remove("accessibility_BW");
    //     btn_acc_bw.classList.remove('active');
    //     btn_acc_by.classList.add('active');
    // }
    if (accorAccessibilityStatus.letterSpacing === 'large') {
        html.classList.add("accessibility_larger_spacing");
        btn_acc_spacing.classList.add('active');
    }
    if (accorAccessibilityStatus.font === 'readable') {
        html.classList.add("accessibility_readable_font");
        btn_acc_font.classList.add('active');
    }
    if (accorAccessibilityStatus.animation === 'animation') {
        html.classList.add("accessibility_no_animation");
        if (countTime < 1) {
            // First time give time to load the Sliders
            setTimeout(function () {
                animationSliders(0);
            }, 3000)
        } else {
            animationSliders(0);
        }
        countTime++;
        btn_acc_anim.classList.add('active');
    }
    if (reset) {
        // html.classList.remove("accessibility_BW");
        // html.classList.remove("accessibility_BY");
        html.classList.remove("accessibility_readable_font");
        html.classList.remove("accessibility_larger_spacing");
        html.classList.remove("accessibility_no_animation");
        // btn_acc_bw.classList.remove('active');
        // btn_acc_by.classList.remove('active');
        btn_acc_spacing.classList.remove('active');
        btn_acc_font.classList.remove('active');
        if (btn_acc_anim.classList.contains('active')) {
            btn_acc_anim.classList.remove('active');
            location.reload();
        }
    }
    saveAccessibilityStatus();
}

let btnAccessibilityPanel = document.getElementById("accessibility_btn");
if (btnAccessibilityPanel != null) {
    accessibilityChange();
}


function animationSliders(time) {
    if (window.slideShowMain !== undefined) {
        window.slideShowMain.params.speed = time;
    }
    // if (window.$startSmallSwiper !== undefined) {
    //     window.$startSmallSwiper.params.speed = time;
    // }
    // if (window.$startFullSwiper !== undefined) {
    //     window.$startFullSwiper.params.speed = time;
    // }
}


function accessibilityChange() {
    // btn_acc_bw.addEventListener('click', function () {
    //     if (!btn_acc_bw.classList.contains('active')) {
    //         accorAccessibilityStatus.color = 'bw';
    //         applyAccessibilityStatus();
    //     } else {
    //         accorAccessibilityStatus.color = undefined;
    //         saveAccessibilityStatus()
    //         btn_acc_bw.classList.remove('active');
    //         html.classList.remove("accessibility_BW");
    //     }
    // });
    // btn_acc_by.addEventListener('click', function () {
    //     if (!btn_acc_by.classList.contains('active')) {
    //         accorAccessibilityStatus.color = 'by';
    //         applyAccessibilityStatus();
    //     } else {
    //         accorAccessibilityStatus.color = undefined;
    //         saveAccessibilityStatus()
    //         btn_acc_by.classList.remove('active');
    //         html.classList.remove("accessibility_BY");
    //     }
    // });
    btn_acc_font.addEventListener('click', function () {
        if (!btn_acc_font.classList.contains('active')) {
            accorAccessibilityStatus.font = 'readable';
            applyAccessibilityStatus();
        } else {
            accorAccessibilityStatus.font = undefined;
            saveAccessibilityStatus();
            btn_acc_font.classList.remove('active');
            html.classList.remove("accessibility_readable_font");
        }
    });
    btn_acc_spacing.addEventListener('click', function () {
        if (!document.querySelector('#acc_larger_spacing').classList.contains('active')) {
            accorAccessibilityStatus.letterSpacing = 'large';
            applyAccessibilityStatus();
        } else {
            accorAccessibilityStatus.letterSpacing = undefined;
            saveAccessibilityStatus()
            btn_acc_spacing.classList.remove('active');
            html.classList.remove("accessibility_larger_spacing");
        }
    });
    btn_acc_anim.addEventListener('click', function () {
        if (!btn_acc_anim.classList.contains('active')) {
            accorAccessibilityStatus.animation = 'animation';
            countTime++;
            applyAccessibilityStatus();
            location.reload();
        } else {
            accorAccessibilityStatus.animation = undefined;
            saveAccessibilityStatus()
            btn_acc_anim.classList.remove('active');
            html.classList.remove("accessibility_no_animation");
            animationSliders(300);
            countTime++;
            location.reload();
        }
    });


    btn_acc_reset.addEventListener('click', function () {
        accorAccessibilityStatus = {
            // color: 'default',
            // fontSize: 'normal',
            letterSpacing: 'normal',
            animation: 'normal',
        };
        applyAccessibilityStatus(true);
    });
}

// Preload when html have dyslexic class loaded. Removed because is using Arial

// No colors changes
// setTimeout(function () {
//     var htmlTag = document.getElementsByTagName('html');
//     if (htmlTag[0].getAttribute('hc') != null) {
//         // Fix background color button on High Contrast Chrome Extension
//         // The Chrome is not reading the high-contrast
//         var css = '.button-background-gold::after, #backtotop-container-button #scrolltotop.show { background: white; } #backtotop-container-button #scrolltotop::after {border-bottom-color: white;}',
//             head = document.head || document.getElementsByTagName('head')[0],
//             style = document.createElement('style');
//
//         head.appendChild(style);
//
//         style.type = 'text/css';
//         if (style.styleSheet) {
//             // This is required for IE8 and below.
//             style.styleSheet.cssText = css;
//         } else {
//             style.appendChild(document.createTextNode(css));
//         }
//
//     }
// }, 150);
